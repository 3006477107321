<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3>Statistik</h3>
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

        <h5>Darstellung</h5>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

        {{ /* {active: activeMainButton === 'monthButton'} */ }}
        <b-button
            :variant="[activeMainButton === 'monthButton' ? 'primary' : 'outline-primary']"
            class="mr-10"
            :class="[activeMainButton === 'monthButton' ? 'button-primary' : 'button-outline-primary']"
            id="monthButton"
            @click="toggleMainButton('monthButton', 'month')"
        >Monate</b-button>
        <b-button
            :variant="[activeMainButton === 'weekButton' ? 'primary' : 'outline-primary']"
            class="mr-10"
            :class="[activeMainButton === 'weekButton' ? 'button-primary' : 'button-outline-primary']"
            id="weekButton"
            @click="toggleMainButton('weekButton', 'week')"
        >Kalenderwochen</b-button>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mt-3">
      <b-col cols="12" md="auto">

        <h5>Hauptkategorien</h5>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

          <b-button
              v-for="(category, index) in getMainCategories"
              :key="index"
              :variant="[category.active ? 'primary' : 'outline-primary']"
              class="mr-10 mt-3"
              :class="[category.active ? 'button-primary' : 'button-outline-primary']"
              @click="collectFilter(category.main_category_id, 'main')"
          >{{ category.main_category }}</b-button>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mt-3">
      <b-col cols="12" md="auto">

        <h5>Unterkategorien</h5>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

          {{ /* :class="{active: category.active}" */ }}
          <b-button
              v-for="(category, index) in getSubCategories"
              :key="index"
              :variant="[category.active ? 'primary' : 'outline-primary']"
              class="mr-10 mt-3"
              :class="[category.active ? 'button-primary' : 'button-outline-primary']"
              @click="collectFilter(category.sub_category_id, 'sub')"
          >{{ category.sub_category }}</b-button>

      </b-col>
    </b-row>

    <div
      v-if="chartType === 'month'"
      >
      <b-row
          class="justify-content-md-center mb-3 mt-6"
      >
        <b-col cols="12" md="auto">

          <h3>Monate</h3>

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <chart-turnover-months ref="chartTurnoverMonths"></chart-turnover-months>

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <chart-visitor-months ref="chartVisitorMonths"></chart-visitor-months>


        </b-col>
      </b-row>

    </div>

    <div
        v-if="chartType === 'week'"
    >
      <b-row
          class="justify-content-md-center mb-3 mt-6"
      >
        <b-col cols="12" md="auto">

          <h3>Kalenderwochen</h3>

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <chart-turnover-weeks ref="chartTurnoverWeeks"></chart-turnover-weeks>

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <chart-visitor-weeks ref="chartVisitorWeeks"></chart-visitor-weeks>


        </b-col>
      </b-row>

    </div>

    <b-row class="justify-content-md-center mb-3 mt-6">
      <b-col cols="12" md="auto">

        <h5 class="mb-3">Hinweise zur Benutzung</h5>
        <ul>
          <li><strong>Darstellung:</strong> Klick auf <strong>Monate</strong> zeigt die Auswertung nach Monaten. Klick auf <strong>Kalenderwochen</strong> zeigt die Kalenderwochen eines Jahres.</li>
          <li><strong>Filter:</strong> aktivieren / deaktivieren der Buttons schränkt die Ergebnisse ein.</li>
          <li><strong>Zoom-Funktion in den Charts:</strong> mittels Mausrad können Sie die Ansichten vergrößern / verkleinern.</li>
          <li><strong>Detailansichten:</strong> Klick auf die Werte zeigt die Detailwerte.</li>
          <li>Ihre Daten bleiben immer gleich, unabhängig der ausgewählten Kategorien.</li>
        </ul>

      </b-col>
    </b-row>


  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import chartTurnoverWeeks from "@/components/chartTurnoverWeeks";
import chartVisitorWeeks from "@/components/chartVisitorWeeks";
import chartTurnoverMonths from "@/components/chartTurnoverMonths";
import chartVisitorMonths from "@/components/chartVisitorMonths";

export default {
  name: "areaStatistic",
  components: {
    chartTurnoverWeeks,
    chartVisitorWeeks,
    chartTurnoverMonths,
    chartVisitorMonths
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      buttons: {},
      activeMainButton: 'monthButton',
      chartType: 'month',
      activeButtons: [],
    }
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

  },
  created() {

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getUser',
      'getIsLoggedIn',
      'getMainCategories',
      'getSubCategories'
    ]),
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setCategoryActive'
    ]),
    ...mapGetters([
        'getStatisticFilterButtons',
        'getIsLoggedIn'
    ]),
    toggleMainButton(name, type) {
      //console.log(name);

      this.activeMainButton = name;
      this.chartType = type;

    },
    collectFilter(id, type) {

      //console.log(id);
      //console.log(type);

      //let buttons = this.buttons;
      let buttons = this.$store.getters.getStatisticFilterButtons;
      let obj = {};

      obj.category_id = id;
      obj.type = type;

      if (typeof buttons[id] === "undefined") {
        //console.log('add ' + id);
        buttons[id] = id;

        obj.active = true;

      } else {
        //console.log('lösche ' + id);
        delete buttons[id];

        obj.active = false;
      }

      this.$store.dispatch('setCategoryActive', obj);
      this.$store.dispatch('setStatisticFilterButtons', buttons);

      //console.log(buttons);
      //console.log('hier');

      /*
      const keys = Object.keys(buttons);
      keys.forEach((key) => {
        //console.log(`${key}: ${buttons[key]}`);
        //console.log(buttons);
        //console.log(buttons[key]);
      });
       */

      //this.buttons = buttons;

      if (this.chartType === 'month') {
        this.$refs.chartTurnoverMonths.getChartData();
        this.$refs.chartVisitorMonths.getChartData();
      } else {
        this.$refs.chartTurnoverWeeks.getChartData();
        this.$refs.chartVisitorWeeks.getChartData();
      }


    }
  },
}
</script>

<style scoped>

.mr-10 {
  margin-right: 10px;
}

.mb-3 {
  margin-bottom: 20px;
}

.mt-3 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 40px !important;
}

</style>