<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3>Passwort vergessen Aktivierung</h3>
      </b-col>
    </b-row>

    <b-row
        class="justify-content-md-center mb-3"
        v-if="activation"
    >
      <b-col col md="auto">
        <b-alert
            variant="success"
            show
        >
          <p>Vielen Dank!<br/>Ihnen wurden neue Zugangsdaten zugesendet.</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row
        class="justify-content-md-center mb-3"
        v-if="!activation && activationChecked"
    >
      <b-col col md="auto">
        <b-alert
            variant="danger"
            show
        >
          <p><strong>Fehler!</strong><br/>Der Aktivierungslink ist falsch oder abgelaufen. Bitte wiederholen Sie den Vorgang.</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row
        class="justify-content-md-center"
    >
      <p><b-link to="/login" class="link-primary">zurück</b-link></p>
    </b-row>

  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "areaPasswordForgottenActivation",
  props: ['user_key'],
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      activation: false,
      activationChecked: false,
    }
  },
  mounted() {

    //console.log(this.user_key);
//d37f14209ef69e8ab66c58d1fb5awww7524
    if (this.user_key === undefined || this.user_key == '') {
      console.log('user_key ist nicht gesetzt');
      //return this.newUserData;
      this.activation = false;
      this.activationChecked = true;
      this.$router.push('/login');
      return;
    }

    this.sendPwForgottenActivationKey();

  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
    ]),
    ...mapGetters([
    ]),
    async sendPwForgottenActivationKey() {

      const response = await axios.post(
          this.API_URL,
          {
            type: 'send_password_forgotten_activation_key',
            user_key: this.user_key,
          }
      );

      //console.log(response);

      if (response.data.error === undefined) {
        //console.log('atrue');
        this.activation = true;
      } else {
        //console.log('afalse');
        this.activation = false;
        this.activationChecked = true;
      }

    },
  }
}
</script>

<style scoped>

</style>