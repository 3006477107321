import { render, staticRenderFns } from "./areaUserEdit.vue?vue&type=template&id=38564fcb&scoped=true&"
import script from "./areaUserEdit.vue?vue&type=script&lang=js&"
export * from "./areaUserEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38564fcb",
  null
  
)

export default component.exports