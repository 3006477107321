<template>
  <div id="app">
    <NavigationBar></NavigationBar>
    <div class="space-left">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";


export default {
  name: 'App',
  components: {
    NavigationBar
  }
}
</script>

<style>

body {
  background-image: url('~@/assets/back.png');
  background-repeat: repeat-y;
  background-color: #ffffff;
}

/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/lato-v22-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-100.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/lato-v22-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/lato-v22-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-100italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/lato-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/lato-v22-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/lato-v22-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/lato-v22-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/lato-v22-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/lato-v22-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-900.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/lato-v22-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/lato-v22-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/lato-v22-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/lato-v22-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/lato-v22-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/lato-v22-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}


#app {
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.button-primary {
  background-color: #83D0F5;
  border: 1px solid #83D0F5;
}

.button-primary:visited,
.button-primary:active,
.button-primary:link,
.button-primary:focus,
.button-primary:focus-within,
.button-primary:focus-visible
{
  background-color: #83D0F5 !important;
  border: 1px solid #83D0F5 !important;
}

.button-primary:hover
{
  background-color: #96C6F6 !important;
  border: 1px solid #96C6F6 !important;
}

.button-outline-primary {
  background-color: #ffffff;
  border: 1px solid #83D0F5;
  color: #2F8EED;
}

.button-outline-primary:visited,
.button-outline-primary:link,
.button-outline-primary:focus,
.button-outline-primary:focus-within,
.button-outline-primary:focus-visible
{
  background-color: #83D0F5 !important;
  border: 1px solid #83D0F5 !important;
  color: #ffffff;
}

.button-outline-primary:hover,
.button-outline-primary:active
{
  background-color: #96C6F6 !important;
  border: 1px solid #96C6F6 !important;
  color: #ffffff;
}

.link-primary {
  color: #2F8EED;
}

.link-primary:visited,
.link-primary:active,
.link-primary:link,
.link-primary:focus,
.link-primary:focus-within,
.link-primary:focus-visible
{
  color: #2F8EED !important;
}

.link-primary:hover
{
  color: #83D0F5 !important;
}

.space-left {
  padding-left: 80px;
}
</style>
