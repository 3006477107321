import { render, staticRenderFns } from "./areaStatistic.vue?vue&type=template&id=06387a36&scoped=true&"
import script from "./areaStatistic.vue?vue&type=script&lang=js&"
export * from "./areaStatistic.vue?vue&type=script&lang=js&"
import style0 from "./areaStatistic.vue?vue&type=style&index=0&id=06387a36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06387a36",
  null
  
)

export default component.exports