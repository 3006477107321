<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3>Passwort vergessen</h3>
      </b-col>
    </b-row>

    <b-row
        class="justify-content-md-center mb-3"
        v-if="!emailTransmitted"
    >
      <b-col col md="auto">
        <p>Bitte geben Sie Ihre E-Mail-Adresse ein.<br/>Sie erhalten im Anschluss ein neues Passwort zugeschickt, insofern Sie bereits registriert sind.</p>
      </b-col>
    </b-row>

    <b-row
        class="justify-content-md-center mb-3"
        v-else
    >
      <b-col col md="auto">
        <b-alert
          variant="success"
          show
          >
        <p>Vielen Dank!<br/>Insofern Ihre E-Mail-Adresse registriert ist, erhalten Sie in den nächsten Minuten eine E-Mail mit einem Aktivierungslink, um Ihr neues Passwort zu erhalten.</p>
        </b-alert>

        <p><b-link to="/login" class="link-primary">zurück</b-link></p>
      </b-col>
    </b-row>

    <b-row
        class="justify-content-md-center"
        v-if="!emailTransmitted"
    >
      <b-form>
        <b-form-group
            label="E-Mail:"
            label-for="email"
        >
          <b-form-input
              id="email"
              v-model="email"
              type="email"
              placeholder="E-Mail-Adresse"
              required
          ></b-form-input>
        </b-form-group>

        <b-button
            variant="primary"
            class="button-primary"
            @click="sendPwForgottenMail"
        >Passwort anfordern</b-button>
        <b-link to="/login" class="ml-4 link-primary">zurück</b-link>
      </b-form>
    </b-row>

  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "areaPasswordForgotten",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      email: '',
      emailTransmitted: false,
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      'setCategoryActive'
    ]),
    ...mapGetters([
      'getStatisticFilterButtons',
      'getIsLoggedIn'
    ]),
    async sendPwForgottenMail() {
      console.log(this.email);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'send_password_forgotten_mail',
            email: this.email,
          }
      );

      console.log(response);

      this.emailTransmitted = true;
    },
  }
}
</script>

<style scoped>

</style>