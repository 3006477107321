import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import areaLogin from "@/components/areaLogin";
import areaPasswordForgotten from "@/components/areaPasswordForgotten";
import areaUserList from "@/components/areaUserList";
import areaUserEdit from "@/components/areaUserEdit";
import areaRecording from "@/components/areaRecording";
import areaRecordingWeeks from "@/components/areaRecordingWeeks";
import areaRecordingMonths from "@/components/areaRecordingMonths";
import areaStatistic from "@/components/areaStatistic";
import areaPasswordForgottenActivation from "@/components/areaPasswordForgottenActivation";
//import { mapGetters } from 'vuex';
import store from './store'

const routes = [
    {
        path: '/',
        name: 'Login',
        component: areaLogin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: areaLogin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/passwordForgotten',
        name: 'Passwort vergessen',
        component: areaPasswordForgotten,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/passwordForgottenActivation/:user_key',
        name: 'Passwort vergessen Aktivierung',
        component: areaPasswordForgottenActivation,
        props: true,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/userList',
        name: 'Benutzerliste',
        component: areaUserList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/userEdit/:user_id',
        name: 'Benutzer bearbeiten',
        component: areaUserEdit,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/userEdit',
        name: 'Benutzer bearbeiten',
        component: areaUserEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recording',
        name: 'Daten erfassen',
        component: areaRecording,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recordingWeeks',
        name: 'Daten erfassen - Wochen',
        component: areaRecordingWeeks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recordingMonths',
        name: 'Daten erfassen - Monate',
        component: areaRecordingMonths,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/statistic',
        name: 'Statistik',
        component: areaStatistic,
        meta: {
            requiresAuth: false
        }
    }
]

const router = new VueRouter ({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {

    if (!store.getters.getIsLoggedIn && to.meta.requiresAuth) {
        next('/login' );
        //next()
    } else {
        next() // go to wherever I'm going
    }

})

//this.$router

export default router;