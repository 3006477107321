<template>
  <div class="mbottom">
    <b-navbar toggleable="lg" type="light" variant="faded">
      <b-navbar-brand to="/"><img src="~@/assets/INTOURA_Logo.svg" width="200" class="mr-4 logoposition"></b-navbar-brand>

      <div
          v-if="getIsLoggedIn"
      >
        <b-navbar-toggle target="nav-toggle-collapse"></b-navbar-toggle>

          <b-collapse id="nav-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              {{ /* v-if="getUserLevel != 30" */ }}
              <b-nav-item
                  to="/recording"
              >Erfassung</b-nav-item>
              <b-nav-item
                  to="/statistic"
              >Statistik</b-nav-item>
              <b-nav-item
                  to="/userList"
                  v-if="getUserLevel >= 30"
              >Benutzer</b-nav-item>

            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-navbar-nav>
                <b-nav-item href="/">
                  <font-awesome-icon
                      :icon="['fa', 'sign-out-alt']"
                      class="cursor-pointer mr-3 navlink"
                  />
                  {{ /* level: getUserLevel */ }}
                </b-nav-item>
              </b-navbar-nav>
            </b-navbar-nav>

          </b-collapse>
      </div>
    </b-navbar>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: "NavigationBar",
  computed: {
    ...mapGetters([
      'getIsLoggedIn',
      'getUserLevel'
    ]),
  },
}
</script>

<style scoped>


.mbottom {
  margin-bottom: 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1);
  font-weight: normal;
}

.nav-link:hover {
  color: rgba(255, 0, 0, 1);
}

.navlink {
  font-weight: normal;
  color: #000000;
}

.logoposition {
  margin-left: 26px;
}


</style>