<template>
  <b-container fluid>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto" class="mb-3">
        <h3>{{ salutation }}</h3>
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h5>Hier finden Sie Ihre Aufgaben für die <u>wöchentliche</u> Datenerfassung.</h5>

        <br/>
        <div
            v-if="recordingDatesCounter('sumWeekly') > 0"
            >
          <p>Bitte tragen Sie <strong>{{ recordingDatesCounter('sumWeekly') }}</strong> Datensätze ein.<br/>
          Umsätze: <strong>{{ recordingDatesCounter('turnover') }}</strong><br/>
          Besucher: <strong>{{ recordingDatesCounter('visitor') }}</strong></p>
          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingWeeks"
            >Wochendaten erfassen</b-button></p>
        </div>

        <div
          v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>

        <br/>
        <h5>Hier finden Sie Ihre Aufgaben für die <u>monatliche</u> Datenerfassung.</h5>
        <br/>
        <div
            v-if="recordingMonthCounter('sumMonthly') > 0"
        >
          <p>Bitte tragen Sie <strong>{{ recordingMonthCounter('sumMonthly') }}</strong> Datensätze ein.<br/>
            Umsätze: <strong>{{ recordingMonthCounter('turnover') }}</strong><br/>
            Besucher: <strong>{{ recordingMonthCounter('visitor') }}</strong></p>
          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingMonths"
            >Monatsdaten erfassen</b-button></p>
        </div>

        <div
            v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>

      </b-col>
    </b-row>


  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from "axios";

export default {
  name: "areaRecording",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      months: {}
    }
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

  },
  created() {

    var today = new Date();
    const lastSunday = new Date();
    lastSunday.setDate(today.getDate() - today.getDay());
    var lastSundayFormatted = new Date(lastSunday).toISOString().slice(0, 10);
    //console.log(lastSundayFormatted);

    const currentYear = new Date().getFullYear();
    const previousYear =  currentYear - 1;

    let dates = this.getDates(new Date(previousYear + "-01-01T00:00:00"), new Date(lastSundayFormatted + "T00:00:00"));
    //console.log('Dates: ', dates);

    var startDate = this.$store.getters.getRecordingStartDate;

    //console.log('start: ', startDate);

    if (startDate == '') this.$store.dispatch('setRecordingStartDate', previousYear + "-01-01");
    this.$store.dispatch('setRecordingEndDate', lastSundayFormatted);
    this.$store.dispatch('setRecordingDates', dates);

    this.getMonths();

    this.sendRecords();

    //console.log(this.getRecordingDates);

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getRecordingMonths',
      'getUser',
      'getIsLoggedIn',
      'getRecords'
    ]),

    salutation() {
      const user = this.getUser;
      let salutation = '';

      //console.log(user);

      if (user.salutation == 1) {

        salutation = 'Herzlich willkommen, Herr ' + user.lastname + '!';

      } else {

        salutation = 'Herzlich willkommen, Frau ' + user.lastname + '!';

      }

      return salutation;
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    ...mapGetters([
    ]),
    getMonths() {

      let dates = this.getRecordingDates;

      //month
      let months = {};
      const keys = Object.keys(dates);
      keys.forEach((key) => {
        //console.log(`${key}: ${dates[key]}`);
        //console.log(dates[key]['yearmonth']);
        //months[dates[key]['yearmonth']].push(dates[key]);

        if (months[dates[key]['yearmonth']] === undefined) {
          months[dates[key]['yearmonth']] = dates[key];
        }
      });
      //console.log(months);

      //this.months = months;

      this.$store.dispatch('setRecordingMonths', months);

      return months;

    },
    recordingDatesCounter(type) {

      let dates = this.getRecordingDates;
      let records = this.getRecords;

      var turnoverCounter = 0;
      var visitorCounter = 0;
      var datesCounter = dates.length;

      if (records.turnover !== undefined) {
        turnoverCounter = Object.keys(records.turnover).length;
      }

      if (records.visitor !== undefined) {
        visitorCounter = Object.keys(records.visitor).length;
      }

      //console.log(records);
      //console.log(dates);

      //console.log(turnoverCounter);
      //console.log(visitorCounter);
      var diff = ( datesCounter * 2 ) - ( turnoverCounter + visitorCounter );

      //this.countTodoTurnoverWeekly = datesCounter - turnoverCounter;
      //this.countTodoVisitorWeekly = datesCounter - visitorCounter;
      //console.log(diff);
      //console.log(this.getRecordingDates);
      //return this.getRecordingDates.length;

      var out = 0;

      switch(type) {

        default:
        case 'sumWeekly':
          out = diff;
          break;

        case 'visitor':
          out = datesCounter - visitorCounter;
          break;

        case 'turnover':
          out = datesCounter - turnoverCounter;
          break;

      }

      return out;

    },
    recordingMonthCounter(type) {

      let months = this.getRecordingMonths;

      /*
      let months = {};

      if (Object.keys(this.months).length > 0) {
        months = this.months;
      } else {
        months = this.getMonths();
      }

       */

      let records = this.getRecords;

      var turnoverCounter = 0;
      var visitorCounter = 0;
      var monthsCounter = Object.keys(months).length;

      //console.log('Monate: ', monthsCounter);

      if (records.turnoverMonth !== undefined) {
        turnoverCounter = Object.keys(records.turnoverMonth).length;
      }

      if (records.visitorMonth !== undefined) {
        visitorCounter = Object.keys(records.visitorMonth).length;
      }

      //console.log('turnover ', turnoverCounter);
      //console.log('visitor ', visitorCounter);

      var diff = ( monthsCounter * 2 ) - ( turnoverCounter + visitorCounter );

      var out = 0;

      switch(type) {

        default:
        case 'sumMonthly':
          out = diff;
          break;

        case 'visitor':
          out = monthsCounter - visitorCounter;
          break;

        case 'turnover':
          out = monthsCounter - turnoverCounter;
          break;

      }

      return out;

    },
    async sendRecords() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      var startDate = this.$store.getters.getRecordingStartDate;
      var endDate = this.$store.getters.getRecordingEndDate;

      //console.log('start: ', startDate);
      //console.log('end: ', endDate);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_records',
            startDate: startDate,
            endDate: endDate,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setRecords', {});

      } else {

        //console.log(response.data);
        this.$store.dispatch('setRecords', response.data);

      }
    },
    getFormattedDate(date) {

      var res = date.toISOString().slice(0,10).split(/-/);
      return res[2] + '.' + res[1] + '.' + res[0];

    },
    getDates(startDate, endDate) {

      let monthnames = {
        1: 'Januar',
        2: 'Februar',
        3: 'März',
        4: 'April',
        5: 'Mai',
        6: 'Juni',
        7: 'Juli',
        8: 'August',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Dezember',
      };
      //console.log(monthnames);

      //first the function and variable definitions
      let dates = []
      const addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      //now our Sunday check
      let currentDate = startDate
      if (currentDate.getDay() > 0) {
        //console.log('not a sunday...adjusting')
        currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));

        var new_start_year = currentDate.getFullYear();
        var new_start_month = currentDate.getMonth() + 1;
        var new_start_day = currentDate.getDay();

        //console.log(currentDate.getDay());

        if (new_start_month <= 9) new_start_month = '0' + new_start_month;
        if (new_start_day <= 9) new_start_day = '0' + new_start_day;

        //console.log('neues Dateum');
        this.$store.dispatch('setRecordingStartDate', new_start_year + '-' + new_start_month + "-" + new_start_day);
      }

      while (currentDate <= endDate) {
        let endWeekDate = addDays.call(currentDate, 7);

        var week = (new Date(currentDate)).getWeek();
        var old_year = currentDate.getFullYear();
        var new_year = endWeekDate.getFullYear();

        var month = currentDate.getMonth() + 1;
        //console.log(month);

        var year = old_year;

        if (week == 1) {
          year = new_year;
        }

        var month_str = month;

        if (month <= 9) month_str = '0' + month;

        var yearmonth = year.toString() + month_str;

        var week_str = week;

        if (week <= 9) week_str = '0' + week;

        dates.push(
            {
              begin: currentDate,
              begin_formatted: this.getFormattedDate(currentDate),
              end: endWeekDate,
              end_formatted: this.getFormattedDate(endWeekDate),
              week: week,
              week_str: week_str,
              month: month,
              month_str: month_str,
              monthname: monthnames[month],
              year: year,
              yearmonth: yearmonth
            });
        currentDate = addDays.call(currentDate, 7);
      }
      return dates;
    },

  }
}
</script>

<style scoped>

</style>